export const landingSectionsData = [
  {
    imageUrl: "eoi.png",
    label: "Digital forms",
    title: "Spend less time on admin. Spend more time talking to customers.",
    paragraphs: [
      "No more printing, scanning or trying to decipher messy handwriting! Customise your Expression of Interest and share it digitally for buyers to complete online in minutes. Generate a shareable PDF for your contracts team or solicitor to start preparing the contract.",
      "Purchaser names, solicitor details and contract conditions are automatically stored under the applicable lot so you can continue to manage the sale and stay informed about key due dates. That means less time on data entry and more time selling!",
    ],
    class: "eoi",
    animationImg: "fade-right",
    animationText: "fade-up",
  },
  {
    imageUrl: "eoi-docusign.png",
    label: "Docusign",
    title:
      "Generate land contracts ready for signature in less than 60 seconds.",
    paragraphs: [
      "Go to contract in minutes, not days. Connect to your DocuSign or REI Forms Live account to turn a completed Expression of Interest into a pre-populated land contract.",
      "All information is automatically synced and carried over including purchaser details, solicitor details and contract conditions. Simply review, edit and hit send!",
    ],
    class: "eoi-docusign",
    animationImg: "fade-left",
    animationText: "fade-up",
  },
  {
    imageUrl: "initial-deposit.png",
    label: "Online payments",
    title:
      "Track initial deposit payments straight into your nominated trust account.",
    paragraphs: [
      "Tired of following up buyers for proof that an initial deposit has been paid to secure a lot? We’ve got you covered.",
      "Securely collect an initial holding deposit as a final step for buyers to complete when submitting their Expression of Interest online. Once you’ve approved their Expression of Interest the deposit will be transferred directly into your nominated trust account. Track the payment and get notified once the funds are cleared in your trust account.",
    ],
    class: "initial-deposit",
    animationImg: "fade-right",
    animationText: "fade-up",
  },
  {
    imageUrl: "buyer-insights.png",
    label: "Buyer insights",
    title: "Understand who your buyers are and where they are coming from.",
    paragraphs: [
      "Get insights about where your leads are coming from, how long they are spending on your website and which lots they are clicking on most. Generate reports to understand which marketing dollars are getting the best return on investment.",
      "Personalise your sales outreach based on the journey your lead has taken to arrive in your inbox.",
    ],
    class: "buyer-insights",
    animationImg: "fade-left",
    animationText: "fade-up",
  },
  {
    animationImg: "fade-right",
    animationText: "fade-up",
    imageUrl: "reports.png",
    label: "Reports",
    title: "Generate branded developer reports with a few clicks.",
    paragraphs: [
      "Track weekly, monthly, and quarterly activity and sales progress across one or multiple projects.",
      "Generate actionable reports in minutes, custom-branded and ready to share with your developer clients or internally with your team.",
    ],
    class: "reports",
  },
  {
    imageUrl: "buyer-portal.png",
    label: "Buyer portal",
    title: "Keep your buyers informed every step of the way.",
    paragraphs: [
      "Let your buyers create their own login to a buyer portal where they can track their purchase from Expression of Interest to Settlement.",
      "No need to keep answering the same questions over and over again. From a simplified and intuitive interface buyers will be able to stay up to date on key due dates and construction progress.",
    ],
    class: "buyer-portal",
    animationImg: "fade-left",
    animationText: "fade-up",
  },
];

export const builderLandingSectionsData = [
  {
    animationImg: "fade-left",
    animationText: "fade-up",
    class: "concierge-service",
    label: "Sales Concierge",
    imageUrl: "concierge-service.png",
    title: "Consider us an extension of your in-house sales team.",
    paragraphs: [
      "No time to respond to customer enquiries or create build quotes? Our sales concierge is on hand to answer any immediate questions and nurture prospective buyers until they are ready to move forward with you.",
    ],
    hasMoreContent: false,
    contactUsButton: true,
  },
  {
    animationImg: "fade-right",
    animationText: "fade-up",
    class: "simple-onboarding",
    label: "Simple Onboarding",
    imageUrl: "simple-onboarding.png",
    title:
      "Send us your house designs and floor plans. We’ll take care of the rest.",
    paragraphs: [
      "We want to make it as easy as possible for you to start working with us. You’ll have a dedicated Customer Success Manager who is responsible for liaising with the developer, creating your profile and uploading your house designs. You just tell us which lots you want to build on and which home designs fit best.",
    ],
    hasMoreContent: true,
    contactUsButton: false,
  },
];
export const builderLandingDocumentsData = [
  {
    label: "Floorplans",
    image: "floorplans-icon.svg",
    optional: false,
  },
  {
    label: "Exterior Facade Options",
    optional: false,
    image: "facades-icon.svg",
  },
  {
    label: "List of Inclusions",
    image: "list-of-inclusions-icon.svg",
    optional: false,
  },
  {
    label: "Interior Home Images",
    image: "interior-home-images-icon.svg",
    optional: true,
  },
];

export const builderDemoImagesData = {
  facades: [
    {
      url: "facade-1.jpg",
      label: "Design 1",
    },
    {
      url: "facade-2.jpg",
      label: "Design 2",
    },
    {
      url: "facade-3.jpg",
      label: "Design 3",
    },
  ],
  optionalStep: [
    {
      url: "optional-step-1.png",
      label: "Optional Step",
    },
    {
      url: "optional-step-2.png",
      label: "Optional Step",
    },
  ],
  floorplans: [
    {
      url: "floorplan-1.png",
      label: "Option A",
    },
    {
      url: "floorplan-2.png",
      label: "Option B",
    },
    {
      url: "floorplan-3.png",
      label: "Option C",
    },
  ],
};

export const landingFloorplansData = [
  {
    size: 220,
    width: 10.4,
    depth: 22.9,
    features: ["Walk-in pantry", "3 spacious living areas"],
    url: builderDemoImagesData.floorplans[0].url,
  },
  {
    size: 225,
    width: 10.8,
    depth: 23.8,
    features: ["Walk-in pantry", "3 spacious living areas"],
    url: builderDemoImagesData.floorplans[1].url,
  },
  {
    size: 234,
    width: 22.8,
    depth: 10.9,
    features: ["Walk-in pantry", "3 spacious living areas"],
    url: builderDemoImagesData.floorplans[2].url,
  },
];
export const landingHousePartsData = {
  bathroom: {
    quantity: 2,
  },
  loungeRoom: {
    quantity: 3,
  },
  garage: 2,
  bedroom: 4,
};
